<template>
  <v-row no-gutters>
    <Parent />
    <v-dialog max-width="450" v-model="dialog" :persistent="dialogLoading">
      <v-card :loading="dialogLoading">
        <v-card-title>
          {{ $t("page_link_posts_list_title") }}
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ $t("name") }}</th>
                  <th>{{ $t("time_read") }}</th>
                </tr>
              </thead>

              <tbody v-if="!dialogLoading">
                <tr
                  v-for="(view, index) in linkPostViews"
                  :key="`view-${index}`"
                  @click="openUserDetail(view.user.id)"
                >
                  <td>
                    {{ `${view.user.name} ${view.user.surname}` }}
                  </td>
                  <td>{{ getTimeFromTimeStamp(view.created_at) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-col class="mt-3" cols="12">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="auto">{{ $t("link_post") }}</v-col>

            <v-spacer></v-spacer>

            <v-col cols="3">
              <TableSearch
                :search.sync="search"
                @update:search="getDataFromApi"
              ></TableSearch>
            </v-col>

            <v-col cols="auto">
              <v-btn
                @click="openAddModel()"
                color="primary"
                text
                class="text-capitalize font-weight-regular"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("page_link_posts_list_create_new_text") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0">
          <Table
            :headers="headers"
            :items="allLinkPosts"
            :number-of-pages="linkPostPagination.records_total"
            :total-records="linkPostPagination.total_pages"
            @update:options="updateTable"
            @click:row="goToEdit"
          >
            <template v-slot:item.postdate="{ item }">
              <span class="text-capitalize">{{ item.postdate }}</span>
            </template>

            <template v-slot:item.title="{ item }">
              <span class="text-capitalize">{{ item.title }}</span>
            </template>

            <template v-slot:item.publisher="{ item }">
              <span class="text-capitalize">{{ item.publisher }}</span>
            </template>

            <template v-slot:item.clicks="{ item }">
              <v-col
                @click.stop="item.clicks ? getUserViewedList(item.id) : null"
                class="text-capitalize"
                :class="item.clicks ? 'text-link-post-clickable' : ''"
              >
                {{ item.clicks }}
              </v-col>
            </template>

            <template v-slot:item.status="{ item }">
              <span
                class="text-capitalize"
                :class="item.status === 1 ? 'color-green' : 'color-red'"
              >
                {{ getStatusText(item.status) }}
              </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn @click="openEditModel(item)" icon color="secondary">
                <v-icon size="12">$edit</v-icon>
              </v-btn>
              <v-btn icon color="secondary">
                <v-icon
                  @click.stop="
                    deleteItem = item;
                    deleteItemDlg = true;
                  "
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        <addModel></addModel>
        <editModel></editModel>
      </v-row>
    </v-col>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="deleteItemDlg"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{ $t("confirmation") }}</v-toolbar>
        <v-card-text>
          <v-row class="text-h7 pa-3 pt-7">
            {{ $t("page_link_posts_list_confirmation_message") }}
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="formStatus"
            color="primary"
            class="mr-3"
            @click="deleteItemConfirm"
            >{{ $t("confirm") }}</v-btn
          >
          <v-btn
            :disabled="formStatus"
            color="red"
            dark
            @click="deleteItemDlg = false"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Parent from "@/views/admin/Notification/index";
import Table from "@/components/admin/partials/Table/Table";
import editModel from "@/views/admin/Notification/LinkPost/editModel";
import addModel from "@/views/admin/Notification/LinkPost/addModel";
import TableSearch from "@/components/admin/partials/Search/TableSearch";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "index",
  components: { Table, Parent, editModel, addModel, TableSearch },
  created() {
    this.getDataFromApi();
    this.$root.$on("onSavePostLink", () => {
      this.getDataFromApi();
    });
  },
  computed: {
    ...mapGetters(["allLinkPosts", "linkPostPagination", "linkPostViews"])
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("page_link_posts_table_header_post_date"),
          align: "center",
          value: "post_date"
        },
        {
          text: this.$t("page_link_posts_table_header_post_title"),
          align: "center",
          value: "title"
        },
        {
          text: this.$t("page_link_posts_table_header_post_publisher"),
          align: "center",
          value: "publisher"
        },
        {
          text: this.$t("page_link_posts_table_header_post_clicks"),
          align: "center",
          value: "clicks"
        },
        {
          text: this.$t("page_link_posts_table_header_post_status"),
          align: "center",
          value: "status"
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      totalPageCount: 1,
      totalRecordCount: 50,
      loading: false,
      deleteItemDlg: false,
      deleteItem: {},
      formStatus: false,
      initialLoad: true,
      dialog: false,
      dialogLoading: false,
      search: ""
    };
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    getDataFromApi(e) {
      this.loading = true;
      let data = {};
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc[0] ? "desc" : "asc";
        data.sort = e.sortBy[0];
      } else {
        data.sort = "post_date";
        data.order = "desc";
      }
      if (e?.page !== undefined && e?.paginate !== undefined) {
        data.page = e.page;
        data.paginate = e.paginate;
      }

      if (this.search) {
        data.search = this.search;
      }

      this.$store
        .dispatch("GET_ALL_LINK_POSTS", data)
        .then(() => {
          if (this.initialLoad) this.initialLoad = false;
        })
        .finally(() => (this.loading = false));
    },
    openAddModel() {
      this.$root.$emit("openAddModel", true);
    },
    openEditModel(item) {
      this.$root.$emit("openEditModel", item);
    },
    deleteItemConfirm() {
      this.deleteItemDlg = false;
      this.formStatus = true;
      this.$store.dispatch("DELETE_LINK_POST", this.deleteItem).then(() => {
        this.notifications.splice(
          this.notifications.indexOf(this.deleteItem),
          1
        );
        this.formStatus = false;
      });
    },
    getStatusText(status) {
      if (status === 1) return this.$t("publish");
      return this.$t("private");
    },
    getUserViewedList(id) {
      this.dialog = true;
      this.dialogLoading = true;
      this.$store
        .dispatch("GET_ALL_LINK_POST_USER_VIEWS", {
          notification_link_post_id: id
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    getTimeFromTimeStamp(timestamp) {
      return dayjs(timestamp).format("YYYY/MM/DD HH:mm");
    },
    openUserDetail(id) {
      let routeData = this.$router.resolve({
        name: "UserDetail",
        params: { id: id }
      });

      window.open(routeData.href, "_blank");
    },
    goToEdit(item) {
      this.openEditModel(item);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
