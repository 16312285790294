var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    ref: "localModelOpened",
    attrs: {
      "persistent": "",
      "width": "460px"
    },
    model: {
      value: _vm.localModelOpened,
      callback: function callback($$v) {
        _vm.localModelOpened = $$v;
      },
      expression: "localModelOpened"
    }
  }, [_c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    ref: "observer_form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-card', {
    staticClass: "modalclass pb-10"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("page_link_posts_create_title")) + " ")]), _c('v-btn', {
    staticClass: "my-auto",
    attrs: {
      "color": "red",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeModel();
      }
    }
  }, [_c('v-icon', [_vm._v("$close")])], 1)], 1), _c('v-divider'), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', _vm._l(_vm.fields, function (field) {
          return _c('tr', {
            key: field.name,
            staticClass: "input-row"
          }, [_c('td', [_c('v-label', [_vm._v(_vm._s(field.label))])], 1), field.type === 'text' ? _c('td', [_c('validation-provider', {
            attrs: {
              "name": field.name,
              "rules": field.rules
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref) {
                var errors = _ref.errors;
                return [_c('v-text-field', {
                  staticClass: "input-class widthfull",
                  attrs: {
                    "placeholder": field.placeholder,
                    "dense": "",
                    "error-messages": errors
                  },
                  model: {
                    value: field.value,
                    callback: function callback($$v) {
                      _vm.$set(field, "value", $$v);
                    },
                    expression: "field.value"
                  }
                })];
              }
            }], null, true)
          })], 1) : _vm._e(), field.type === 'date' ? _c('td', [_c('validation-provider', {
            attrs: {
              "name": field.name,
              "rules": field.rules
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('div', {
                  staticClass: "input-class widthfull"
                }, [_c('DatePickerInput', {
                  attrs: {
                    "placeholder": field.placeholder,
                    "dense": "",
                    "error-messages": errors,
                    "separator": '-',
                    "field": field
                  },
                  model: {
                    value: field.value,
                    callback: function callback($$v) {
                      _vm.$set(field, "value", $$v);
                    },
                    expression: "field.value"
                  }
                })], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), field.type === 'toggle' ? _c('td', [_c('validation-provider', {
            attrs: {
              "name": field.name,
              "rules": field.rules
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var errors = _ref3.errors;
                return [_c('v-switch', {
                  staticClass: "mt-0",
                  attrs: {
                    "error-messages": errors,
                    "error": errors.length !== 0,
                    "hide-details": errors.length <= 0,
                    "color": field.value ? '#046C21' : '#D14141',
                    "label": field.value == 1 ? _vm.$t('publish') : _vm.$t('private')
                  },
                  model: {
                    value: field.value,
                    callback: function callback($$v) {
                      _vm.$set(field, "value", $$v);
                    },
                    expression: "field.value"
                  }
                })];
              }
            }], null, true)
          })], 1) : _vm._e()]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "btn-center"
  }, [_c('v-btn', {
    staticClass: "btn-class-submit",
    attrs: {
      "type": "submit",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus
    }
  }, [_vm._v(" 投稿する ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }