var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('Parent'), _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": _vm.dialogLoading
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.dialogLoading
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("page_link_posts_list_title")) + " ")]), _c('v-card-text', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("time_read")))])])]), !_vm.dialogLoading ? _c('tbody', _vm._l(_vm.linkPostViews, function (view, index) {
          return _c('tr', {
            key: "view-".concat(index),
            on: {
              "click": function click($event) {
                return _vm.openUserDetail(view.user.id);
              }
            }
          }, [_c('td', [_vm._v(" " + _vm._s("".concat(view.user.name, " ").concat(view.user.surname)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.getTimeFromTimeStamp(view.created_at)))])]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.$t("link_post")))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('TableSearch', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "update:search": [function ($event) {
        _vm.search = $event;
      }, _vm.getDataFromApi]
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openAddModel();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("page_link_posts_list_create_new_text")) + " ")], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.allLinkPosts,
      "number-of-pages": _vm.linkPostPagination.records_total,
      "total-records": _vm.linkPostPagination.total_pages
    },
    on: {
      "update:options": _vm.updateTable,
      "click:row": _vm.goToEdit
    },
    scopedSlots: _vm._u([{
      key: "item.postdate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.postdate))])];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.title))])];
      }
    }, {
      key: "item.publisher",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(_vm._s(item.publisher))])];
      }
    }, {
      key: "item.clicks",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-col', {
          staticClass: "text-capitalize",
          "class": item.clicks ? 'text-link-post-clickable' : '',
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              item.clicks ? _vm.getUserViewedList(item.id) : null;
            }
          }
        }, [_vm._v(" " + _vm._s(item.clicks) + " ")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-capitalize",
          "class": item.status === 1 ? 'color-green' : 'color-red'
        }, [_vm._v(" " + _vm._s(_vm.getStatusText(item.status)) + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.openEditModel(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "12"
          }
        }, [_vm._v("$edit")])], 1), _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.deleteItem = item;
              _vm.deleteItemDlg = true;
            }
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('addModel'), _c('editModel')], 1)], 1), _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "500"
    },
    model: {
      value: _vm.deleteItemDlg,
      callback: function callback($$v) {
        _vm.deleteItemDlg = $$v;
      },
      expression: "deleteItemDlg"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("confirmation")))]), _c('v-card-text', [_c('v-row', {
    staticClass: "text-h7 pa-3 pt-7"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_link_posts_list_confirmation_message")) + " ")])], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "disabled": _vm.formStatus,
      "color": "primary"
    },
    on: {
      "click": _vm.deleteItemConfirm
    }
  }, [_vm._v(_vm._s(_vm.$t("confirm")))]), _c('v-btn', {
    attrs: {
      "disabled": _vm.formStatus,
      "color": "red",
      "dark": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteItemDlg = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }