<template>
  <v-dialog
    ref="localModelOpened"
    v-model="localModelOpened"
    persistent
    width="460px"
  >
    <validation-observer ref="observer">
      <v-form @submit.prevent="submit" ref="observer_form">
        <v-card class="modalclass pb-10">
          <div class="d-flex justify-space-between">
            <v-card-title>
              {{ $t("page_link_posts_edit_title") }}
            </v-card-title>
            <v-btn class="my-auto" color="red" text @click="closeModel()">
              <v-icon>$close</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="field in fields" :key="field.name" class="input-row">
                  <td>
                    <v-label>{{ field.label }}</v-label>
                  </td>
                  <td v-if="field.type === 'text'">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <v-text-field
                        :placeholder="field.placeholder"
                        dense
                        class="input-class widthfull"
                        v-model="field.value"
                        :error-messages="errors"
                      >
                      </v-text-field>
                    </validation-provider>
                  </td>

                  <td v-if="field.type === 'date'">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <div class="input-class widthfull">
                        <DatePickerInput
                          :placeholder="field.placeholder"
                          dense
                          v-model="field.value"
                          :error-messages="errors"
                          :separator="'-'"
                          :field="field"
                        ></DatePickerInput>
                      </div>
                    </validation-provider>
                  </td>

                  <td v-if="field.type === 'toggle'">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <v-switch
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length <= 0"
                        :input-value="!field.value"
                        v-model="field.value"
                        :color="field.value == '1' ? '#046C21' : '#D14141'"
                        :label="
                          field.value == '1' ? $t('publish') : $t('private')
                        "
                        class="mt-0"
                      >
                      </v-switch>
                    </validation-provider>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="btn-center">
            <v-btn
              type="submit"
              :disabled="formStatus"
              :loading="formStatus"
              class="btn-class-submit"
            >
              投稿する
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import DatePickerInput from "@/components/admin/ui/DatePickerInput";
import dayjs from "@/plugins/dayjs";
export default {
  components: {
    DatePickerInput
  },
  data() {
    return {
      colorObj: { checked: "#046C21", unchecked: "#D14141" },
      localModelOpened: false,
      editItem: {},
      fields: [
        {
          label: this.$t("page_link_posts_create_field_post_date"),
          placeholder: this.$t(
            "page_link_posts_create_field_post_date_placeholder"
          ),
          name: "post_date",
          type: "date",
          value: dayjs().format("YYYY-MM-DD"),
          rules: "required",
          locale: "ja",
          date_format: "YYYY-MM-DD",
          date: "",
          menu: false,
          class: "mt-0"
        },
        {
          label: this.$t("page_link_posts_edit_field_title"),
          name: "title",
          type: "text",
          placeholder: this.$t("page_link_posts_edit_field_title_placeholder"),
          value: "",
          rules: "required"
        },
        {
          label: this.$t("page_link_posts_edit_field_url"),
          name: "url",
          type: "text",
          placeholder: this.$t("page_link_posts_edit_field_url_placeholder"),
          value: "",
          rules: "required|url"
        },
        {
          label: this.$t("page_link_posts_edit_field_publisher"),
          name: "publisher",
          type: "text",
          placeholder: this.$t(
            "page_link_posts_edit_field_publisher_placeholder"
          ),
          value: "",
          rules: "required"
        },
        {
          label: this.$t("page_link_posts_edit_field_status"),
          name: "status",
          type: "toggle",
          placeholder: this.$t("page_link_posts_edit_field_status_placeholder"),
          value: "1",
          rules: "required"
        }
      ],
      formStatus: false
    };
  },
  created() {
    let this_this = this;
    this.$root.$on("openEditModel", function(value) {
      this_this.localModelOpened = true;
      this_this.editItem = value;
      this_this.editItemFun(value);
    });
  },
  methods: {
    closeModel() {
      this.localModelOpened = false;
    },
    editItemFun(item) {
      for (const field of this.fields) {
        if (field.additional_field) {
          field.additional_field.value = item[field.additional_field.name];
        }
        if (field.name === "status") {
          field.value = item[field.name] == 1;
          continue;
        }
        field.value = item?.[field.name];
      }
    },
    async submit() {
      this.formStatus = true;
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        let data = {};

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, "additional_field")) {
            data[field.additional_field.name] = field.additional_field.value;
          }
          data[field.name] = field.value;
        });

        data.id = this.editItem.id;

        this.$store
          .dispatch("EDIT_LINK_POST", data)
          .then(result => {
            if (result.status === 200) {
              this.$refs.observer.reset();
              this.$refs.observer_form.reset();
            }
            this.formStatus = false;
            this.localModelOpened = false;
            this.editItem = {};
            this.$root.$emit("onSavePostLink", true);
          })
          .catch(error => {
            this.formStatus = false;
            this.$refs.observer.setErrors(error.data.error.errors);
          });
      });
    },

    getToggleColor(value) {
      if (value) return "#046C21";
      return "#D14141";
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
